import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

export const api = axios.create({
  baseURL: process.env.VUE_APP_API_JWT_URL,
  timeout: 30000,
  withCredentials: true,
});

export const platformApi = axios.create({
  baseURL: process.env.VUE_APP_URL_PLATA,
  timeout: 30000,
});
