import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginPage from '@/pages/Login';
import LobbyPage from '@/pages/Lobby';
import { isAuthenticated } from '@/services/authentication';
import { deleteToken } from '@/utils/cookies';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'LobbyPage',
    component: LobbyPage,
    meta: {
      requiresAuth: true,
      analytics: 'lobby',
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: { analytics: 'login' },
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  const options = {}
  const searchparams = new URLSearchParams(window.location.search);

  if (searchparams.has('nextUrl')) {
    options['query'] = { nextUrl: searchparams.get('nextUrl') };
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      deleteToken();
      options['name'] = 'Login';
      next(options);
    }
    else next(options);
  }
  else {
    next(options);
  }
});

export default router;
