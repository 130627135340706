<template>
  <div class="lobby-page">
    <header-nav />
    <loading :show="showLoader" />
    <v-container class="lobby-container">
      <v-row>
        <v-col cols="12" class="mt-8">
          <h1 class="lobby-description">{{ greetMessage }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
          <v-col cols="12" md="10" xl="9">
            <v-alert type="error" prominent dismissible outlined text class="mt-4">
              Atenção: os atendimentos do canal 0800 nacional foram encerrados. O acesso aos registros de consultorias já realizadas seguirá disponível para consulta.
              <br>
              Lembre-se: <b>RegulaSUS</b> e serviços de <b>diagnóstico</b> estão disponíveis <b>apenas para os profissionais de saúde da Atenção Primária do Rio Grande do Sul</b>.
            </v-alert>
          </v-col>
        <v-spacer />
      </v-row>
      <v-row class="justify-center">
        <v-spacer />
        <v-col cols="10" class="d-flex flex-wrap justify-center px-0">
          <profile-card
            v-for="(role, index) in roles"
            :key="index"
            :description="role.description"
            :role="role.name"
            :url="role.url"
            @emitter-show-loader="showLoader = true" />
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { decodeJwt } from '@/utils/decodeJwt';
  import { getCookies } from '@/utils/cookies';
  import { profiles } from '@/utils/handleUserProfile';

  import HeaderNav from '@/components/HeaderNav';
  import Loading from '@/components/Loading';
  import ProfileCard from '@/components/ProfileCard';

  export default {
    title: 'Lobby - TelessaúdeRS',
    name: 'LobbyPage',
    components: {
      'header-nav': HeaderNav,
      'loading': Loading,
      'profile-card': ProfileCard
    },
    data: () => ({
      roles: [],
      username: '',
      showLoader: false
    }),
    computed: {
      greetMessage() {
        return this.roles.length
          ? `Olá, ${ this.username }. Selecione seu perfil para acessar os serviços.`
          : `Olá, ${ this.username }. Você não possui perfil selecionável.`
      },
    },
    created() {
      const cookies = getCookies();
      const servicetoken = decodeJwt(cookies['servicetoken']);

      this.username = this.formatUsername(servicetoken.claims.username);

      let rolesList = Object.keys(servicetoken.claims.roles).map(service => {
        let userProfiles = [...new Set(servicetoken.claims.roles[service])];
        return userProfiles
          .map(userProfile => profiles[service][userProfile])
          .filter(userProfile => !!userProfile);
      }).flat();

      if (!rolesList.length) return;

      this.roles = rolesList.sort((firstRole, lastRole) => {
        return this.sortByString(firstRole.name, lastRole.name);
      });
    },
    methods: {
      formatUsername(username) {
        return username.split(' ').map((item) => {
          const title = item.toLowerCase();
          return title.charAt(0).toUpperCase() + title.slice(1);
        }).join(' ');
      },
      sortByString(first, last) {
        first = first.toLowerCase();
        last = last.toLowerCase();

        if (first < last) return -1;
        if (last > first) return 1;
        return 0;
      }
    }
  }
</script>

<style scoped>
.lobby-page {
  min-height: 100vh;
  background-color: #F5F5F5;
}

.lobby-description {
  color: #707070;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  font-weight: normal;
  text-align: center;
}
</style>
