<template>
  <v-app-bar color="#008C88">
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <a
          class="logout-link"
          :href="logoutURL"
          v-bind="attrs"
          v-on="on"
        >
          <img
            class="logout-height"
            :src="require('@/assets/img/logout.svg')"
          >
        </a>
      </template>
      <span>Sair</span>
    </v-tooltip>
    <img
      :src="require('@/assets/img/logo_branco.svg')"
      class="ma-auto logo-size"
      alt="Logo Plataforma Telessaúde"
    >
  </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderNav',
  computed: {
    logoutURL() {
      return process.env.VUE_APP_URL_PLATA + '/accounts/logout/';
    }
  }
}
</script>

<style scoped>
.logout-link {
  line-height: 0;
  padding: 3px;
}
.logout-height { height: 25px; }
.logo-size { width: 218px; }
</style>
