<template>
  <div :class="open">
    <v-progress-circular
      :size="50"
      color="#19B2AC"
      indeterminate
      class="ma-auto"
    />
  </div>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, required: true, default: false }
  },
  computed: {
    open() {
      return this.show ? "overlay-container" : "d-none";
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
