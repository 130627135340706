<template>
  <a
    :href="url"
    :title="role"
    class="card-profile"
    @click="prevent($event, url)"
  >
    <img
      :src="require('@/assets/img/lobby_account.svg')"
      class="card-profile-icon"
      alt="Ícone usuário"
    >
    <div class="card-profile-body">
      <h2 class="card-profile-body-title">
        {{ role }}
      </h2>
      <p class="card-profile-body-description tertiary--text">
        {{ description }}
      </p>
    </div>
  </a>
</template>

<script>
import { debounce } from '@/utils/debounce';

export default {
  name: "ProfileCard",
  props: {
    description: { type: String, required: false, default: '' },
    role: { type: String, required: true, default: '' },
    url: { type: String, required: true, default: '' },
  },
  methods: {
    prevent(event, url) {
      event.preventDefault();
      this.$emit('emitter-show-loader');
      this.redirect(url);
    },
    redirect: debounce(function(url) {
      window.location.assign(url);
    }, 500),
  }
};
</script>

<style scoped>
.card-profile {
  height: 180px;
  width: 180px;
  margin: 8.5px;
  padding: 0px 10px;

  background-color: #f8f8f8;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  outline: none;
  text-decoration: none;

  transition: border ease;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card-profile:hover,
.card-profile:focus {
  border: 2px solid #19b2ac;
  cursor: pointer;
}

.card-profile-icon {
  width: 58px;
  height: 58px;
  margin: 0 auto 10px;
}

.card-profile-body {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.card-profile-body-title {
  color: #19b2ac;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin: 0px;
}
.card-profile-body-description {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin: 0px;
}

@media screen and (min-width: 1904px) {
  .card-profile {
    height: 224px;
    width: 224px;
    margin: 12.5px;
  }
  .card-profile-icon {
    width: 83px;
    height: 83px;
  }
  .card-profile-body-title {
    font-size: 19px;
  }
}
</style>
