import Vue from 'vue';
import VueGtag from "vue-gtag";
import titleMixin from '@/mixins/titleMixin';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

const app_env = process.env.VUE_APP_ENVIRONMENT;
const IS_DEVELOPMENT = app_env === 'DSV'
const IS_PRODUCTION = app_env === 'PRD'

if (!IS_DEVELOPMENT) {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_ANALYTICS },
  }, router);
}

if (IS_PRODUCTION) {
  const TRACE_PROPAGATION_TARGETS = {
    LegacyPlataform: /^https:\/\/plataformatelessaude\.ufrgs\.br\/api/,
    PlatformX: /^https:\/\/api\.plataformatelessaude\.ufrgs\.br/
  }

  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.7,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: Object.values(TRACE_PROPAGATION_TARGETS),
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

Vue.mixin(titleMixin);
new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
