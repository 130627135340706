<template>
  <div class="carousel">
    <v-carousel
      hide-delimiter-background
      hide-delimiters
      :show-arrows="false"
      height="auto"
      cycle
      interval=15000
    >
      <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
      >
        <div>
          <h1 class="titleSlide">
            {{ slide.title }}
          </h1>
        </div>
        <div class="textSlide">
          {{ slide.text }}
        </div>
      </v-carousel-item>
    </v-carousel>
    <div class="logos">
      <div class="img-container">
        <a
          href="https://saude.rs.gov.br/inicial"
          target="_blank"
        >
          <img
            :src="require('@/assets/img/logo_ses-rs.svg')"
            alt="Logo da Secretária da Saúde do RS"
          >
        </a>
      </div>
      <div class="img-container">
        <a
          href="http://www.ufrgs.br/ufrgs/inicial"
          target="_blank"
        >
          <img
            :src="require('@/assets/img/logo_ufrgs.svg')"
            alt="Logo UFRGS"
          >
        </a>
      </div>
      <div class="img-container">
        <a
          href="https://www.gov.br/saude/pt-br"
          target="_blank"
        >
          <img
            :src="require('@/assets/img/logo_sus.svg')"
            alt="Logo SUS"
          >
        </a>
      </div>
      <div class="img-container">
        <a
          href="https://www.ufrgs.br/telessauders/"
          target="_blank"
        >
          <img
            :src="require('@/assets/img/logo_telessaude.svg')"
            alt="Logo Telessaude Brasil Redes"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CarouselComponent',
    data: () => ({
      slides: [
        {
          title: 'Atenção! Canal 0800 nacional encerrado em 31/08/2024.',
          text: 'Os profissionais que atuam na Atenção Primária do estado do Rio Grande do Sul ainda podem tirar suas dúvidas referentes a casos de encaminhamento para consulta especializada por meio do RegulaSUS (pelo mesmo canal, 0800 644 6543). Ressaltamos que o envio de anexos seguirá apenas para quem atua em unidades de saúde da Atenção Primária do Rio Grande do Sul.'
        },
        {
          title: 'Prezado usuário',
          text: 'Caso você esteja com dificuldade para acessar a plataforma, após trocar a senha, tente limpar os cookies do seu navegador e reinicie o browser. Caso não funcione, abra a plataforma em uma aba anônima e tente realizar o login novamente. Se o problema persistir, entre em contato pelo email plataforma@telessauders.ufrgs.br para um suporte personalizado.'
        },
        {
          title: 'Atenção! Mudança nos encaminhamentos do TeleOftalmo',
          text: 'Os municípios de todas as macrorregiões do Estado do Rio Grande do Sul já devem realizar os encaminhamentos exclusivamente pelo sistema Gercon.',
        },
        {
          title: 'Solicitantes do Rio Grande do Sul',
          text: 'Por indisponibilidade do município de Porto Alegre, as solicitações de exames de espirometria (RespiraNet) do TelessaúdeRS-UFRGS não estão disponíveis para municípios da Macrorregião Metropolitana (1ª e 18ª CRS). No momento não há previsão de retorno.',
        },
        {
          title: 'Disponíveis a qualquer hora e em qualquer lugar',
          text: 'Seja em computadores fixos, portáteis ou dispositivos móveis, é simples receber informações úteis e rápidas para dúvidas de sua prática diária.'
        },
      ],
    })
  }
</script>

<style>
.carousel {
  width: 582px;
}

.titleSlide {
  width: 582px;
  line-height: initial;
  letter-spacing: 0px;
  font-size: 60px;
  color: #fff;
  text-shadow: 0px 3px 6px #00000029;
  font-family: "Lato-Medium", sans-serif;
  font-weight: normal;
  margin-bottom: 64px ;
}

.textSlide {
  line-height: initial;
  letter-spacing: 0px;
  font-size: 24px;
  color: #fff;
  text-shadow: 0px 3px 6px #00000029;
  font-family: "Lato-Regular", sans-serif;
  margin-bottom: 120px;
}

.logos {
  display: flex;
  margin-top: 150px;
}

.img-container {
  float: left;
  width: 25%;
  padding: 5px;
}

.img-container img {
  width: 75%;
  height: 60px;
}

@media screen and (min-width: 1920px) {
  .carousel {
    width: 874px;
  }
  .titleSlide {
    width: 100%;
    font-size: 70px;
  }
  .textSlide {
    font-size: 25px;
  }
}
</style>
