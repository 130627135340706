<template>
  <div class="imgBG">
    <v-row class="containerLogin" >
      <div class="itemLogin"> <form-login-component /> </div>
      <div class="itemCarousel"> <carousel-component /> </div>
    </v-row>
  </div>
</template>

<script>
  import CarouselComponent from '@/components/Carousel';
  import FormLoginComponent from '@/components/FormLogin/FormLogin';
  import { isAuthenticated } from '@/services/authentication';
  import { deleteToken } from '@/utils/cookies';

  export default {
    title: 'Login - TelessaúdeRS',
    name: 'LoginPage',
    components: {
      'carousel-component': CarouselComponent,
      'form-login-component': FormLoginComponent
    },
    beforeCreate() {
      !isAuthenticated() ? deleteToken() : this.$router.push({ name: 'LobbyPage' });
    }
  }
</script>

<style>
body, html {
  background-color:#358A87;
}

.containerLogin {
  padding: 45px 0;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
}

.itemLogin {
  width: 80%;
  max-width: 462px;
}

.itemCarousel {
  display: none;
}

.firstContainer{
  padding:0!important;
}

.imgBG {
  background-image: url('~@/assets/img/bg.png');
  width: 100%;
  height:100%;
  background-size: 100% 100%;
  background-color:#358A87;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1180px){
  .containerLogin {
    flex-flow: row nowrap;
  }
  .itemCarousel {
    display: block;
  }
}
</style>
