import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#19B1AB',
        secondary: '#19B2AC',
        tertiary: '#8E8E8E',
        notSelected: '#DCDCDC',
        quaternary: '#E8E8E8',
        textBlue1: '#006BB0',
        textBlue2: '#0083C9',
        textGreen1: '#19B2AC',
        textRed1: '#DA2121',
        textDark: '#757575',
        processTitle1: '#181919',
        processDetail2: '#040405',
        backgroundProcessQR: '#EBEBEB',
      }
    },
  },
});
