const subtitle0800 = "Regulação e Consultoria";
const subtitleTDX = "Diagnóstico";
const subtitleRemoteCare = "Consulta Remota";
const subtitleDynamicPrescription = "Consultoria Ativa";

const url0800 = process.env.VUE_APP_URL_PLATA + "/teleconsultoria/entrada/";
const urlTDX = process.env.VUE_APP_URL_PLATA + "/servicos/nucleo/todos/caixa_entrada/";
const urlRemoteCare = process.env.VUE_APP_URL_TELESSAUDE + "/consultas-remotas/telenutri";
const urlDynamicPrescription = process.env.VUE_APP_URL_TELESSAUDE + "/consultorias/consultoria-ativa";

const profiles0800 = {
  "administrador": {
    name: "Administrador (0800)",
    description: subtitle0800,
    url: url0800,
  },
  "coordenador": {
    name: "Coordenador (0800)",
    description: subtitle0800,
    url: url0800,
  },
  "monitor": {
    name: "Monitor (0800)",
    description: subtitle0800,
    url: url0800,
  },
  "telerregulador": {
    name: "Regulador",
    description: subtitleDynamicPrescription,
    url: urlDynamicPrescription,
  },
  "solicitante": {
    name: "Solicitante (0800)",
    description: subtitle0800,
    url: process.env.VUE_APP_URL_SOLICITANTE,
  },
  "supervisor": {
    name: "Supervisor (0800)",
    description: subtitle0800,
    url: url0800,
  },
  "teleconsultor": {
    name: "Teleconsultor (0800)",
    description: subtitle0800,
    url: url0800,
  },
};

const profilesTDX = {
  "administrador": {
    name: "Administrador",
    description: subtitleTDX,
    url: urlTDX,
  },
  "coordenador": {
    name: "Coordenador",
    description: subtitleTDX,
    url: urlTDX,
  },
  "monitor": {
    name: "Monitor",
    description: subtitleTDX,
    url: urlTDX,
  },
  "telerregulador": {
    name: "Regulador",
    description: subtitleTDX,
    url: urlTDX,
  },
  "solicitante": {
    name: "Solicitante",
    description: `${subtitleTDX} e TeleFeridas`,
    url: urlTDX,
  },
  "tecnico": {
    name: "Técnico",
    description: subtitleTDX,
    url: urlTDX,
  },
  "teleconsultor": {
    name: "Teleconsultor",
    description: subtitleTDX,
    url: urlTDX,
  },
};

const profilesRemoteCare = {
  "consultaremota_administrador": {
    name: "Administrador",
    description: subtitleRemoteCare,
    url: urlRemoteCare,
  },
  "telenutri_consultor": {
    name: "Consultor (Telenutri)",
    description: subtitleRemoteCare,
    url: urlRemoteCare,
  },
  "consultaremota_monitor": {
    name: "Monitor",
    description: subtitleRemoteCare,
    url: urlRemoteCare,
  },
};

export const profiles = {
  "0800": profiles0800,
  "telediagnostico": profilesTDX,
  "remotecare": profilesRemoteCare,
}
