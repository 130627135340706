export function debounce(func, timeout = 2000) {
  let timer;

  return function() {
    const context = this;
    const args = arguments;

    clearTimeout(timer);

    timer = setTimeout(() => {
      func.apply(context, args);
    }, timeout);
  };
}
