import { api, platformApi } from './api';
import { getCookies } from '@/utils/cookies';
import { decodeJwt } from '@/utils/decodeJwt';

export const login = async (username, password) => {
  return await api.post('auth/', { username, password })
    .then(response => response)
    .catch(error => error.response);
}

export const isAuthenticated = () => {
  const cookiesHashMap = getCookies();

  if (!cookiesHashMap?.servicetoken) return false;

  const sessionToken = decodeJwt(cookiesHashMap.servicetoken);
  const jwtExpiration = sessionToken.exp * 1000;
  const dateToday = new Date();

  return jwtExpiration > dateToday.getTime();
};

export const resetPassword = async (email) => {
  return await platformApi.post('rest-auth/password/reset/', { email })
    .then(response => response.status == 200)
    .catch(() => false);
}
