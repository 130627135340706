<template>
  <v-form
    v-model="formIsValid"
    @submit.prevent
  >
    <v-card flat>
      <v-text-field
        v-model="email"
        required
        class="input-value"
        label="Informe seu e-mail"
        :rules="rules.emailRules"
        :readonly="disableLinks"
        @keyup.enter="validateFormAndResetPassword()"
      />
      <v-btn
        block
        outlined
        :class="[
          isBtnEnable ? 'color-btn' : 'color-btn-disabled',
          'style-btn',
        ]"
        large
        :loading="showBtnLoader"
        @click.prevent="validateFormAndResetPassword()"
      >
        {{ textBtn }}
      </v-btn>
      <div>
        <alert-message
          v-if="alertMessageData"
          :alert-type="alertMessageData.type"
          :class-icon="alertMessageData.classIcon"
          :message="alertMessageData.message"
        />
      </div>
      <v-card-actions class="justify-center">
        <v-btn
          text
          class="forgot-password"
          color="primary"
          :disabled="disableLinks"
          @click="$emit('hideForgotPassword')"
        >
          Voltar ao login
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { debounce } from '@/utils/debounce';
import { resetPassword } from '@/services/authentication';
import AlertMessage from '@/components/AlertMessage.vue';
import { isValidEmail } from '@/utils/validation';

export default {
  name: 'CardResetPassword',
  components: {
    'alert-message': AlertMessage
  },
  data: () => {
    return {
      chipTitle: 'e-mail',
      disableLinks: false,
      email: '',
      errorMessage: '',
      okMessage: '',
      rules: {
        emailRules: [
          v => !!v || 'Campo não pode ser vazio',
          v => isValidEmail(v) || 'Informe um e-mail válido',
        ],
      },
      showBtnLoader: false,
      textBtn: 'Enviar e-mail',
      formIsValid: false,
      makingRequest: false,
    }
  },
  computed: {
    alertMessageData() {
      if (!this.okMessage && !this.errorMessage) return false;
      return {
        classIcon: this.errorMessage ? 'alert-error-icon' : '',
        message: this.okMessage || this.errorMessage,
        type: this.okMessage ? 'sucess' : 'error',
      };
    },
    isBtnEnable() {
      return this.formIsValid && !this.makingRequest;
    },
  },
  methods: {
    debounceCall: debounce(function() {
      this.handleResetPassword();
    }, 500),
    validateFormAndResetPassword() {
      if (!this.formIsValid) return;

      this.disableLinks = true;
      this.makingRequest = true;
      this.showBtnLoader = true;

      this.debounceCall();
    },
    async handleResetPassword() {
      this.errorMessage = '';
      this.okMessage = '';

      try {
        const messageHasSent = await resetPassword(this.email);

        this.makingRequest = false;

        if (messageHasSent) {
          this.okMessage = 'Verifique sua caixa de entrada para continuar a redefinição de senha.';
        } else {
          this.errorMessage = 'E-mail não pode ser enviado.\nVerifique se digitou corretamente.';
        }
      } catch (e) {
        this.errorMessage = 'Sistema temporariamente indisponível!\nSe o erro persistir contate a equipe técnica.';
        console.error(e);
      }

      this.disableLinks = false;
      this.showBtnLoader = false;
    },
  },
};
</script>

<style scoped>
.input-value {
  text-align: left;
  font: normal normal normal 21px/32px Lato;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.54;
  font-size: 16px;
}

.forgot-password {
  text-align: left;
  text-transform: none;
  margin-bottom: 65px;
  font: normal normal 600 14px/35px Lato;
  letter-spacing: 0.12px;
  opacity: 1;
}

.style-btn {
  text-align: center;
  font: normal normal bold 16px/19px Lato;
  color: #FFFFFF;
}

.color-btn {
  background-color: #19b2ac;
}

.color-btn-disabled {
  background-color: #127F7C;
  opacity: 0.6;
  pointer-events: none;
}

.v-card__actions {
  padding: 0;
  padding-bottom: 10px;
}

.v-btn:not(.v-btn--round).v-size--default  {
  padding: 0 4px;
}
</style>
