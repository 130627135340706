<template>
  <div :class="`alert-${alertType}`">
    <span
      v-if="classIcon"
      :class="classIcon"
    />
    <span :class="`alert-${alertType}-message`">
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    alertType: { type: String, required: true, default: 'success' },
    message: { type: String, required: true, default: '' },
    classIcon: { type: String, required: false, default: '' },
  },
}
</script>

<style scoped>
.alert-error {
  border: 1.8px solid #d50000;
  border-radius: 8px;
  margin: 8px;
  padding: 6px 14px;
  display: flex;
  align-items: flex-start;
}

.alert-error .alert-error-message {
  color:#d50000;
  white-space: pre-line;
}

.alert-error .alert-error-icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
  background-image: url('~@/assets/img/login_error.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.alert-sucess {
  border: 1.8px solid #19b2ac;
  border-radius: 8px;
  margin: 8px;
  padding: 6px 14px;
  display: flex;
  align-items: flex-start;
}

.alert-sucess .alert-sucess-message {
  color:#19b2ac;
  white-space: pre-line;
}
</style>
