<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({}),
};
</script>

<style>
  body, html {
    height: 100%;
    margin: 0;
  }
</style>
