export function getCookies() {
  if (!document.cookie) return {};

  const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
  return cookies.reduce((all, cookie) => {
    const [name, value] = cookie.split('=');
    return {
      [name]: value,
      ...all,
    };
  }, {});
}

export function deleteToken() {
  let cookies = getCookies();
  if (cookies?.servicetoken) document.cookie = 'servicetoken=; Max-Age=0';
  if (cookies?.sessionid) document.cookie = 'sessionid=; Max-Age=0';
}
